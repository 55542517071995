import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { nanopoolUserValue } from "../../../recoil/miner/nanopool";
import { red } from "@material-ui/core/colors";
import WorkIcon from "@material-ui/icons/Work";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: red[300],
  },
}));

export default function Nanopool() {
  const classes = useStyles();
  const nanopoolUser = useRecoilValue(nanopoolUserValue);
  const miners = nanopoolUser?.workers || [];

  return (
    <List className="py-0">
      {!_.isEmpty(miners) &&
        miners?.map((items, index) => (
          <ListItem className="px-0">
            <ListItemAvatar>
              <Avatar className={classes.red}>
                <WorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={items.id}
              secondary={`Rating ${items.rating}`}
            />
            <ListItemSecondaryAction className="end-0">
              <Typography>{`${(items.hashrate / 1024).toFixed(
                2
              )} Kh/s`}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );
}
