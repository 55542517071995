import { atom, selector } from "recoil";

export const hiveOsState = atom({
  key: "hiveOsState",
  default: [],
});

export const hiveOsValue = selector({
  key: "hiveOsValue",
  get: ({ get }) => {
    const value = get(hiveOsState);
    return value;
  },
});
