import { atom, selector } from "recoil";

export const cryptoMarketState = atom({
  key: "cryptoMarketState",
  default: null,
});

export const cryptoMarketValue = selector({
  key: "cryptoMarketValue",
  get: ({ get }) => {
    const value = get(cryptoMarketState);
    return value;
  },
});
