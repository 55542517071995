import { atom, selector } from "recoil";

export const cryptoRatesState = atom({
  key: "cryptoRatesState",
  default: 0,
});

export const cryptoRatesValue = selector({
  key: "cryptoRatesValue",
  get: ({ get }) => {
    const value = get(cryptoRatesState);
    return value;
  },
});
