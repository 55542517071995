import { makeStyles } from "@material-ui/core/styles";
import numeral from "numeral";
import { Grid, Card, Typography } from "@material-ui/core";
import styles from "../../theme/style";
import Donuts from "../../components/donuts";
import Charts from "../../components/charts/system/cpu";
import { useRecoilValue } from "recoil";
import { systemOsValue } from "../../recoil/system/os";
import { systemBaseBoardValue } from "../../recoil/system/baseBoard";
import {
  systemCpuValue,
  systemCpuUsedValue,
  systemCpuUsedChartsValue,
  systemCpuTemperaturesValue,
  systemCpuTemperaturesChartsValue,
} from "../../recoil/system/cpu";
import { gpuTempValue } from "../../recoil/system/gpu";
import { systemMemValue } from "../../recoil/system/mem";

const useStylesGlobal = makeStyles(styles);
const useStyles = makeStyles(() => ({
  leftText: {
    paddingRight: 10,
    "&::after": {
      content: "':'",
      paddingLeft: 10,
    },
  },
}));

export default function Home() {
  const classes = useStyles();
  const styles = useStylesGlobal();
  const os = useRecoilValue(systemOsValue);
  const baseBoard = useRecoilValue(systemBaseBoardValue);
  const cpu = useRecoilValue(systemCpuValue);
  const cpuUsed = useRecoilValue(systemCpuUsedValue);
  const cpuUsedCharts = useRecoilValue(systemCpuUsedChartsValue);
  const cpuTemperatures = useRecoilValue(systemCpuTemperaturesValue);
  const cpuTemperaturesCharts = useRecoilValue(
    systemCpuTemperaturesChartsValue
  );
  const gpuTemp = useRecoilValue(gpuTempValue);
  const mem = useRecoilValue(systemMemValue);

  return (
    <>
      <Grid container>
        <Grid item className="pt-4 pb-2 ps-2">
          <Typography>{cpu?.brand || "CPU"}</Typography>
        </Grid>
      </Grid>
      <Card className={styles.card + " py-3 ps-3"} elevation={0}>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item xs={4}>
            <Donuts
              value={cpuUsed || 0}
              text={`${cpuUsed || 0} %`}
              display={"CPU Used"}
            />
          </Grid>
          <Grid item xs={8}>
            <Charts data={cpuUsedCharts} dataKey={"cpuUesd"} />
          </Grid>
        </Grid>
      </Card>

      {os?.platform !== "linux" ? (
        <>
          <Grid container>
            <Grid item className="pt-4 pb-2 ps-2">
              <Typography>{gpuTemp?.name || "Graphics"}</Typography>
            </Grid>
          </Grid>
          <Card className={styles.card + " p-3"} elevation={0}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Donuts
                  value={numeral(gpuTemp?.utilizationGpu).format("0,0")}
                  text={`${numeral(gpuTemp?.utilizationGpu).format("0,0")} %`}
                  display={"GPU"}
                  strokeWidth={13}
                />
              </Grid>
              <Grid item xs={3}>
                <Donuts
                  value={gpuTemp?.temperatureGpu}
                  text={`${gpuTemp?.temperatureGpu || 0} °C`}
                  display={"Temp"}
                  strokeWidth={13}
                />
              </Grid>
              <Grid item xs={3}>
                <Donuts
                  value={
                    gpuTemp
                      ? numeral(gpuTemp?.powerDraw || 0).format("0.00")
                      : 0
                  }
                  text={`${
                    gpuTemp
                      ? numeral(gpuTemp?.powerDraw || 0).format("0.00")
                      : 0
                  } W`}
                  maxValue={gpuTemp?.powerLimit}
                  display={"Power"}
                  strokeWidth={13}
                />
              </Grid>
              <Grid item xs={3}>
                <Donuts
                  value={
                    gpuTemp
                      ? numeral((gpuTemp?.memoryUsed || 0) / 1024).format(
                          "0.00"
                        )
                      : 0
                  }
                  text={`${
                    gpuTemp
                      ? numeral((gpuTemp?.memoryUsed || 0) / 1024).format(
                          "0.00"
                        )
                      : 0
                  } GB`}
                  maxValue={(gpuTemp?.memoryTotal || 100) / 1024}
                  display={"Mem"}
                  strokeWidth={13}
                />
              </Grid>
            </Grid>
          </Card>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item className="pt-4 pb-2 ps-2">
              <Typography>Temperature</Typography>
            </Grid>
          </Grid>
          <Card className={styles.card + " py-3 ps-3"} elevation={0}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={4}>
                <Donuts
                  value={cpuTemperatures || 0}
                  text={`${
                    cpuTemperatures ? numeral(cpuTemperatures).format("0.0") : 0
                  } °C`}
                  display={"CPU Temp"}
                />
              </Grid>
              <Grid item xs={8}>
                <Charts data={cpuTemperaturesCharts} dataKey={"temp"} />
              </Grid>
            </Grid>
          </Card>
        </>
      )}

      <Grid container>
        <Grid item className="pt-4 pb-2 ps-2">
          <Typography>Memory / Info</Typography>
        </Grid>
      </Grid>
      <Card className={styles.card + " p-3"} elevation={0}>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item xs={3}>
            <Donuts
              value={numeral(mem?.percent).format("0,0")}
              text={`${numeral(mem?.percent).format("0,0")} %`}
              display={"Memory Used"}
              strokeWidth={14}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container alignItems="center">
              <Grid item xs={5}>
                <Typography
                  align="right"
                  variant="body2"
                  className={`${classes.leftText} text-truncate`}
                >
                  OS
                </Typography>
                {os?.platform !== "linux" && (
                  <Typography
                    align="right"
                    variant="body2"
                    className={`${classes.leftText} text-truncate`}
                  >
                    OS Build
                  </Typography>
                )}
                <Typography
                  align="right"
                  variant="body2"
                  className={`${classes.leftText} text-truncate`}
                >
                  Arch
                </Typography>
                <Typography
                  align="right"
                  variant="body2"
                  className={`${classes.leftText} text-truncate`}
                >
                  Main Board
                </Typography>
                <Typography
                  align="right"
                  variant="body2"
                  className={`${classes.leftText} text-truncate`}
                >
                  Serial
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2" className="text-truncate">
                  {os?.distro || "Unknown"}
                </Typography>
                {os?.platform !== "linux" && (
                  <Typography variant="body2" className="text-truncate">
                    {os?.build || "Unknown"}
                  </Typography>
                )}
                <Typography variant="body2" className="text-truncate">
                  {os?.arch || "Unknown"}
                </Typography>
                <Typography variant="body2" className="text-truncate">
                  {`${baseBoard?.manufacturer || "Unknown"} ${
                    baseBoard?.model || ""
                  }`}
                </Typography>
                <Typography variant="body2" className="text-truncate">
                  {baseBoard?.serial || "Unknown"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
