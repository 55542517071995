import { atom, selector } from "recoil";

export const systemDiskState = atom({
  key: "systemDiskState",
  default: null,
});

export const systemDiskValue = selector({
  key: "systemDiskValue",
  get: ({ get }) => {
    const value = get(systemDiskState);
    return value;
  },
});
