import { makeStyles } from "@material-ui/core/styles";
import numeral from "numeral";
import { Grid, Card, Typography } from "@material-ui/core";
import styles from "../../theme/style";
import Donuts from "../../components/donuts";
import { useRecoilValue } from "recoil";
import { hiveOsValue } from "../../recoil/rig/hiveos";
import Gpu from "../../components/lists/rig/gpu";
import _ from "lodash";
import moment from "moment";

const useStylesGlobal = makeStyles(styles);
const useStyles = makeStyles(() => ({
  leftText: {
    paddingRight: 10,
    "&::after": {
      content: "':'",
      paddingLeft: 10,
    },
  },
}));

export default function Rig() {
  const classes = useStyles();
  const styles = useStylesGlobal();
  const hiveos = useRecoilValue(hiveOsValue);
  const {
    name = "Black Pearl",
    versions = [],
    stats = [],
    ip_addresses = "Unknown",
  } = hiveos;
  const { miner, coin, hash, shares } =
    hiveos?.miners_summary?.hashrates[0] || "";
  const { cputemp, memory } = hiveos?.hardware_stats || "";
  const { power_draw } = hiveos?.stats || "";
  const { cpu, motherboard, disk } = hiveos?.hardware_info || "";
  const boot_time = moment.unix(stats?.boot_time).format();

  return (
    <>
      <Grid container>
        <Grid item className="pt-4 pb-2 ps-2">
          <Typography>{name}</Typography>
        </Grid>
      </Grid>
      <Card className={styles.card + " p-4"} elevation={0}>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item xs={4}>
            {stats?.online ? (
              <img
                className="img-fluid p-3"
                src="./assets/images/checked.png"
                alt="checked"
              />
            ) : (
              <img
                className="img-fluid p-3"
                src="./assets/images/cancel.png"
                alt="checked"
              />
            )}
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h2" align="center" className="mb-3">
              {stats?.online ? "Online" : "Offline"}
            </Typography>
            <Grid container alignItems="center">
              <Grid item xs={5}>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Total
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Accepted
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Rejected
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Booted
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  IP
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="text-truncate">
                  {(hash / Math.pow(10, 3) || 0).toFixed(2)} Mh
                </Typography>
                <Typography className="text-truncate">
                  {shares?.accepted || 0}
                </Typography>
                <Typography className="text-truncate">
                  {shares?.rejected || 0}
                </Typography>
                <Typography className="text-truncate">
                  {moment(boot_time).startOf("minute").fromNow()}
                </Typography>
                <Typography className="text-truncate">
                  {ip_addresses}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Grid container>
        <Grid item className="pt-4 pb-2 ps-2">
          <Typography>Info</Typography>
        </Grid>
      </Grid>
      <Card className={styles.card + " p-4"} elevation={0}>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item xs={4}>
            <img
              className="img-fluid p-3"
              src="./assets/images/laptop.png"
              alt="checked"
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container alignItems="center">
              <Grid item xs={5}>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  CPU
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Board
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Model
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Bios
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Cores
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Disk
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="text-truncate">
                  {cpu?.model || "Unknown"}
                </Typography>
                <Typography className="text-truncate">
                  {motherboard?.manufacturer || "Unknown"}
                </Typography>
                <Typography className="text-truncate">
                  {motherboard?.model || "Unknown"}
                </Typography>
                <Typography className="text-truncate">
                  {motherboard?.bios || "Unknown"}
                </Typography>
                <Typography className="text-truncate">
                  {cpu?.cores || "Unknown"}
                </Typography>
                <Typography className="text-truncate">
                  {disk?.model || "Unknown"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Grid container>
        <Grid item className="pt-4 pb-2 ps-2">
          <Typography>Version</Typography>
        </Grid>
      </Grid>
      <Card className={styles.card + " p-4"} elevation={0}>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item xs={4}>
            <img
              className="img-fluid p-2"
              src="./assets/images/version.png"
              alt="checked"
            />
          </Grid>
          <Grid item xs={8}>
            <Grid container alignItems="center">
              <Grid item xs={5}>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Hive
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Kernel
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  AMD
                </Typography>
                <Typography
                  align="right"
                  className={`${classes.leftText} text-truncate`}
                >
                  Nvidia
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography className="text-truncate">
                  {versions?.hive || "Unknown"}
                </Typography>
                <Typography className="text-truncate">
                  {versions?.kernel || "Unknown"}
                </Typography>
                <Typography className="text-truncate">
                  {versions?.amd_driver || "Unknown"}
                </Typography>
                <Typography className="text-truncate">
                  {versions?.nvidia_driver || "Unknown"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      <Grid container>
        <Grid item className="pt-4 pb-2 ps-2">
          <Typography>{cpu?.model || "CPU"}</Typography>
        </Grid>
      </Grid>
      <Card className={styles.card + " p-3"} elevation={0}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Donuts
              value={cputemp}
              text={`${cputemp || 0} °C`}
              display={"Cpu Temp"}
              strokeWidth={13}
            />
          </Grid>
          <Grid item xs={4}>
            <Donuts
              value={(memory?.total || 0) - (memory?.free || 0)}
              text={`${numeral(
                ((memory?.total || 0) - (memory?.free || 0)) / 1024
              ).format("0.0")} GB`}
              maxValue={memory?.total}
              display={`Mem ${numeral((memory?.total || 0) / 1024).format(
                "0.0"
              )} GB`}
              strokeWidth={13}
            />
          </Grid>
          <Grid item xs={4}>
            <Donuts
              value={power_draw ? numeral(power_draw || 0).format("0.00") : 0}
              text={`${
                power_draw ? numeral(power_draw || 0).format("0") : 0
              } W`}
              maxValue={750}
              display={"Power 750 W"}
              strokeWidth={13}
            />
          </Grid>
        </Grid>
      </Card>

      {!_.isEmpty(hiveos?.gpu_info && hiveos?.gpu_stats) && (
        <>
          <Grid container>
            <Grid item className="pt-4 pb-2 ps-2">
              <Typography>{`Workers (${miner}-${coin})`}</Typography>
            </Grid>
          </Grid>
          <Gpu />
        </>
      )}
    </>
  );
}
