import { atom, selector } from "recoil";

export const systemOsState = atom({
  key: "systemOsState",
  default: null,
});

export const systemOsValue = selector({
  key: "systemOsValue",
  get: ({ get }) => {
    const value = get(systemOsState);
    return value;
  },
});
