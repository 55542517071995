import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  Grid,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { ethermineDashboardValue } from "../../../../recoil/miner/ethermine";
import { dateValue, timeValue } from "../../../../recoil/datatime";
import {
  purple,
  teal,
  deepOrange,
  green,
  red,
  blue,
} from "@material-ui/core/colors";
import _ from "lodash";
import SpeedIcon from "@material-ui/icons/Speed";
import SubjectIcon from "@material-ui/icons/Subject";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import HistoryIcon from "@material-ui/icons/History";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { cryptoMarketValue } from "../../../../recoil/crypto/market";
import { cryptoRatesValue } from "../../../../recoil/crypto/rates";
import MinerChart from "../../../charts/miner/ethermine";
import "moment/locale/th";

const useStyles = makeStyles((theme) => ({
  purple: {
    backgroundColor: purple[300],
  },
  teal: {
    backgroundColor: teal[300],
  },
  deepOrange: {
    backgroundColor: deepOrange[300],
  },
  green: {
    backgroundColor: green[300],
  },
  red: {
    backgroundColor: red[300],
  },
  blue: {
    backgroundColor: blue[300],
  },
}));

const MoneyComponent = React.memo(({ total, date, time }) => {
  return (
    <>
      <Typography>มูลค่าทั้งหมด</Typography>
      <Typography variant="h4" className="pt-4 pb-2" align="center">
        {total ? Intl.NumberFormat().format(total.toFixed(2)) : 0}
      </Typography>
      <Typography variant="body2" align="center">
        ข้อมูล ณ : {time}
      </Typography>
      <Typography variant="body2" className="pb-4" align="center"></Typography>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="body2">{date}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="right">THB</Typography>
        </Grid>
      </Grid>
    </>
  );
});

export default function Ethermine() {
  const classes = useStyles();
  const date = useRecoilValue(dateValue);
  const time = useRecoilValue(timeValue);
  const ethermineDashboard = useRecoilValue(ethermineDashboardValue);
  const crypto = useRecoilValue(cryptoMarketValue);
  const rates = useRecoilValue(cryptoRatesValue);
  const [ethPrice, setEthPrice] = useState(0);

  useEffect(() => {
    const found = crypto?.find((element) => element.id === 2);
    !_.isEmpty(crypto) && setEthPrice(found.lastPrice);
  }, [crypto]);

  const activeWorkers =
    ethermineDashboard?.currentStatistics?.activeWorkers || 0;

  return (
    <>
      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <MoneyComponent
            total={
              ((
                ethermineDashboard?.currentStatistics?.unpaid / Math.pow(10, 18)
              ).toFixed(5) || 0) *
              ethPrice *
              rates
            }
            date={date}
            time={time}
          />
        </Grid>
        <Grid item xs={12}>
          {!_.isEmpty(ethermineDashboard?.statistics) && (
            <MinerChart data={ethermineDashboard?.statistics} />
          )}
        </Grid>
      </Grid>

      <List className="py-0">
        <ListItem className="px-0">
          <ListItemAvatar>
            <Avatar className={activeWorkers ? classes.green : classes.red}>
              <PowerSettingsNewIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"Active Workers"} secondary={"Status"} />
          <ListItemSecondaryAction className="end-0">
            <Typography style={{ color: activeWorkers ? "lightgreen" : "red" }}>
              {activeWorkers}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem className="px-0">
          <ListItemAvatar>
            <Avatar className={classes.blue}>
              <SubjectIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"Report Hashrate"} secondary={"Reported"} />
          <ListItemSecondaryAction className="end-0">
            <Typography>
              {ethermineDashboard
                ? `${(
                    ethermineDashboard?.currentStatistics?.reportedHashrate /
                      Math.pow(10, 6) || 0
                  ).toFixed(2)} Mh`
                : 0}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem className="px-0">
          <ListItemAvatar>
            <Avatar className={classes.deepOrange}>
              <SpeedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"Hashrate"} secondary={"lasted"} />
          <ListItemSecondaryAction className="end-0">
            <Typography>
              {ethermineDashboard
                ? `${(
                    ethermineDashboard?.currentStatistics?.currentHashrate /
                      Math.pow(10, 6) || 0
                  ).toFixed(2)} Mh`
                : 0}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem className="px-0">
          <ListItemAvatar>
            <Avatar className={classes.teal}>
              <AttachMoneyIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"Unpaid Balance"} secondary={"ETH"} />
          <ListItemSecondaryAction className="end-0">
            {(
              ethermineDashboard?.currentStatistics?.unpaid / Math.pow(10, 18)
            ).toFixed(5) || 0}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
}
