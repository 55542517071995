// import CloudIcon from "@material-ui/icons/Cloud";
// import AcUnitIcon from "@material-ui/icons/AcUnit";
// import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import PetsIcon from "@material-ui/icons/Pets";
// import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import CameraIcon from "@material-ui/icons/Camera";
import ListsEthermine from "../../components/lists/miner/ethermine";
// import ListsFlexpool from "../../components/lists/miner/flexpool";
// import ListsNanopool from "../../components/lists/miner/nanopool";
// import ListsNicehash from "../../components/lists/miner/nicehash";
import ListsDuinocoin from "../../components/lists/miner/duinocoin";
// import ListsVerus from "../../components/lists/miner/verus";
// import ListsHpool from "../../components/lists/miner/hpool";

export const TabData = [
  {
    icon: <PetsIcon fontSize="small" />,
    label: "Ethermine",
    tab_panel: <ListsEthermine />,
  },
  // {
  //   icon: <CloudIcon fontSize="small" />,
  //   label: "Flexpool",
  //   tab_panel: <ListsFlexpool />,
  // },
  // {
  //   icon: <AcUnitIcon fontSize="small" />,
  //   label: "Nanopool",
  //   tab_panel: <ListsNanopool />,
  // },
  {
    icon: <CameraIcon fontSize="small" />,
    label: "Duinocoin",
    tab_panel: <ListsDuinocoin />,
  },
  // {
  //   icon: <BeachAccessIcon fontSize="small" />,
  //   label: "Verus",
  //   tab_panel: <ListsVerus />,
  // },
  // {
  //   icon: <BeachAccessIcon fontSize="small" />,
  //   label: "HPool",
  //   tab_panel: <ListsHpool />,
  // },
];
