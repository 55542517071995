import { atom, selector } from "recoil";

export const ethermineDashboardState = atom({
  key: "ethermineDashboardState",
  default: null,
});

export const ethermineDashboardValue = selector({
  key: "ethermineDashboardValue",
  get: ({ get }) => {
    const value = get(ethermineDashboardState);
    return value;
  },
});

export const etherminePayoutsState = atom({
  key: "etherminePayoutsState",
  default: null,
});

export const etherminePayoutsValue = selector({
  key: "etherminePayoutsValue",
  get: ({ get }) => {
    const value = get(etherminePayoutsState);
    return value;
  },
});
