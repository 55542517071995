import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { ethermineDashboardValue } from "../../../recoil/miner/ethermine";
import { red } from "@material-ui/core/colors";
import WorkIcon from "@material-ui/icons/Work";
import _ from "lodash";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: red[300],
  },
}));

export default function Ethermine() {
  const classes = useStyles();
  const ethermineDashboard = useRecoilValue(ethermineDashboardValue);
  const miners = ethermineDashboard?.workers || [];

  return (
    <List className="py-0">
      {!_.isEmpty(miners) &&
        miners?.map((items, index) => (
          <ListItem key={index} className="px-0">
            <ListItemAvatar>
              <Avatar className={classes.red}>
                <WorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={items.worker}
              secondary={moment(moment.unix(items.time).format())
                .startOf("day")
                .fromNow()}
            />
            <ListItemSecondaryAction className="end-0">
              <Typography>{`${(items.currentHashrate / Math.pow(10, 6)).toFixed(
                2
              )} Mh`}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );
}
