import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { systemCpuValue } from "../../../../recoil/system/cpu";
import {
  purple,
  red,
  pink,
  teal,
  cyan,
  deepOrange,
  brown,
  orange,
  lightGreen,
  green,
} from "@material-ui/core/colors";
import _ from "lodash";
import numeral from "numeral";
import EcoIcon from "@material-ui/icons/Eco";
import bytes from "bytes";
import SpeedIcon from "@material-ui/icons/Speed";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StorageIcon from "@material-ui/icons/Storage";
import TollIcon from "@material-ui/icons/Toll";

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: red[300],
  },
  purple: {
    backgroundColor: purple[300],
  },
  pink: {
    backgroundColor: pink[300],
  },
  teal: {
    backgroundColor: teal[300],
  },
  cyan: {
    backgroundColor: cyan[300],
  },
  deepOrange: {
    backgroundColor: deepOrange[300],
  },
  brown: {
    backgroundColor: brown[300],
  },
  orange: {
    backgroundColor: orange[400],
  },
  lightGreen: {
    backgroundColor: lightGreen[400],
  },
  green: {
    backgroundColor: green[400],
  },
}));

export default function Cpu() {
  const classes = useStyles();
  const cpu = useRecoilValue(systemCpuValue);

  return (
    <List className="py-0">
      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.pink}>
            <StarBorderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <div className="text-truncate">{cpu?.brand || "Unknown"}</div>
          }
          secondary={`${cpu?.manufacturer || "Unknown"} ${cpu?.vendor || ""} ${
            cpu?.socket || ""
          }`}
        />
        <ListItemSecondaryAction className="end-0">
          {_.isEmpty(cpu) && <CircularProgress size={25} />}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.purple}>
            <EcoIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Cores"} secondary={"Cores"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(cpu) ? (
            <Typography>{cpu?.physicalCores || 0}</Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.teal}>
            <EcoIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Logical Processors"} secondary={"Thread"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(cpu) ? (
            <Typography>{cpu?.cores || 0}</Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.deepOrange}>
            <SpeedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Speed"} secondary={"Base Speed"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(cpu) ? (
            <Typography>{`${numeral(cpu?.speed).format(
              "0.00"
            )} GHz`}</Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.cyan}>
            <TollIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Virtualization"} secondary={"Status"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(cpu) ? (
            <Typography>
              {cpu?.virtualization ? "Enabled" : "Disabled "}
            </Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.orange}>
            <StorageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"L1 Cache"} secondary={"Cache"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(cpu) ? (
            <Typography>
              {!_.isNil(cpu?.cache?.l1d) && cpu?.cache?.l1d !== ""
                ? bytes(cpu?.cache?.l1d, {
                    unitSeparator: " ",
                  })
                : 0}
            </Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.lightGreen}>
            <StorageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"L2 Cache"} secondary={"Cache"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(cpu) ? (
            <Typography>
              {!_.isNil(cpu?.cache?.l1d) && cpu?.cache?.l1d !== ""
                ? bytes(cpu?.cache?.l2, {
                    unitSeparator: " ",
                  })
                : 0}
            </Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.green}>
            <StorageIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"L3 Cache"} secondary={"Cache"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(cpu) ? (
            <Typography>
              {!_.isNil(cpu?.cache?.l1d) && cpu?.cache?.l1d !== ""
                ? bytes(cpu?.cache?.l3, {
                    unitSeparator: " ",
                  })
                : 0}
            </Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}
