import { colors } from "@material-ui/core";
// import { useRecoilValue } from "recoil";
// import { daskModeValue } from "../recoil/daskMode";

const white = "#FFFFFF";
const black = "#000000";

// const rate = useRecoilValue(daskModeValue);

const palette = {
  black,
  white,
  primary: {
    dark: "#1a1d20",
    main: "#16D5DF",
    light: "#31363a",
  },
  secondary: {
    contrastText: white,
    dark: colors.pink[500],
    main: colors.pink[400],
    light: colors.pink[300],
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[50],
    secondary: colors.blueGrey[600],
    link: colors.blueGrey[100],
  },
  background: {
    primary: "#212529",
    secondary: "#2A2E32",
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};

export default palette;
