import { atom, selector } from "recoil";

export const flexpoolBalanceState = atom({
  key: "flexpoolBalanceState",
  default: null,
});

export const flexpoolBalanceValue = selector({
  key: "flexpoolBalanceValue",
  get: ({ get }) => {
    const value = get(flexpoolBalanceState);
    return value;
  },
});

export const flexpoolWorkerCountState = atom({
  key: "flexpoolWorkerCountState",
  default: null,
});

export const flexpoolWorkerCountValue = selector({
  key: "flexpoolWorkerCountValue",
  get: ({ get }) => {
    const value = get(flexpoolWorkerCountState);
    return value;
  },
});

export const flexpoolStatsState = atom({
  key: "flexpoolStatsState",
  default: null,
});

export const flexpoolStatsValue = selector({
  key: "flexpoolStatsValue",
  get: ({ get }) => {
    const value = get(flexpoolStatsState);
    return value;
  },
});

export const flexpoolChartState = atom({
  key: "flexpoolChartState",
  default: null,
});

export const flexpoolChartValue = selector({
  key: "flexpoolChartValue",
  get: ({ get }) => {
    const value = get(flexpoolChartState);
    return value;
  },
});

export const flexpoolWorkersState = atom({
  key: "flexpoolWorkersState",
  default: null,
});

export const flexpoolWorkersValue = selector({
  key: "flexpoolWorkersValue",
  get: ({ get }) => {
    const value = get(flexpoolWorkersState);
    return value;
  },
});
