import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  ListItemSecondaryAction,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { systemDiskValue } from "../../../../recoil/system/disk";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import teal from "@material-ui/core/colors/teal";
import LinearProgress from "@material-ui/core/LinearProgress";
import bytes from "bytes";
import _ from "lodash";
import cliTruncate from "cli-truncate";

const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 9,
    borderRadius: 2,
  },
  colorPrimary: {
    backgroundColor: theme.palette.background.primary,
  },
  bar: {
    backgroundColor: theme.palette.primary,
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  teal: {
    backgroundColor: teal[300],
  },
}));

export default function Disk() {
  const classes = useStyles();
  const disk = useRecoilValue(systemDiskValue);

  return (
    <List className="py-0">
      {!_.isEmpty(disk) ? (
        disk.map((items, index) => (
          <ListItem key={index} className="px-0">
            <ListItemAvatar className="mt-1">
              <Avatar className={classes.teal}>
                <LaptopMacIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography gutterBottom className="pb-1">{`${cliTruncate(
                  items?.device,
                  5
                )} ${cliTruncate(items?.fstype, 10)}`}</Typography>
              }
              secondary={
                <CustomLinearProgress
                  variant="determinate"
                  value={items?.percent}
                />
              }
            />
            <ListItemSecondaryAction
              style={{ marginTop: "-10px", marginRight: "-15px" }}
            >
              <Typography variant="body2">{`${bytes(items?.free, {
                unitSeparator: " ",
              })} free of ${bytes(items?.total, {
                unitSeparator: " ",
              })}`}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))
      ) : (
        <Grid container justifyContent="center" className="py-5">
          <CircularProgress />
        </Grid>
      )}
    </List>
  );
}
