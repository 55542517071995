import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../theme/style";
import { useTheme } from "@material-ui/core/styles";
import { dateValue, timeValue } from "../../recoil/datatime";
import { Tabs, Tab, Typography, Box, Grid, Card } from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import ListsBalances from "../../components/lists/crypto/balances";
import ListsCrypto from "../../components/lists/crypto/market";
import { useRecoilValue } from "recoil";
import {
  bitkubBalancesValue,
  binanceBalancesValue,
} from "../../recoil/crypto/balances";
import { cryptoRatesValue } from "../../recoil/crypto/rates";
import { cryptoMarketValue } from "../../recoil/crypto/market";
import _ from "lodash";
import { useCoin } from "../../hooks/useCoin";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const MoneyComponent = React.memo(({ total, date, time }) => {
  return (
    <>
      <Typography>มูลค่าทั้งหมด</Typography>
      <Typography variant="h4" className="pt-4 pb-2" align="center">
        {Intl.NumberFormat().format(total.toFixed(2))}
      </Typography>
      <Typography variant="body2" align="center">
        ข้อมูล ณ : {time}
      </Typography>
      <Typography variant="body2" className="pb-4" align="center"></Typography>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="body2">{date}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="right">THB</Typography>
        </Grid>
      </Grid>
    </>
  );
});

const useStylesGlobal = makeStyles(styles);

export default function FullWidthTabs() {
  const styles = useStylesGlobal();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const date = useRecoilValue(dateValue);
  const time = useRecoilValue(timeValue);
  const bitkubBalances = useRecoilValue(bitkubBalancesValue);
  const binanceBalances = useRecoilValue(binanceBalancesValue);
  const crypto = useRecoilValue(cryptoMarketValue);
  const rates = useRecoilValue(cryptoRatesValue);
  const [total, setTotal] = useState(0);
  const [thaibaht, setThaibaht] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const SumBinance = () => {
    let balancesBinance = binanceBalances?.map((binance, index) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      let coinId = useCoin(binance?.asset);
      let cryptoList = crypto?.find((elem) => elem.id === coinId);
      let lastPrice =
        (coinId === 0 ? 1 : Number(cryptoList?.lastPrice) * rates) || 0;
      let coinBinance = Number(binance?.free || 0);
      let total = Number((coinBinance * lastPrice).toFixed(2));
      let data = {
        ...binance,
        total,
      };
      return data;
    });

    let final = balancesBinance?.reduce((a, b) => ({
      total: a.total + b.total,
    }));

    return final;
  };

  const CalculateMoney = async () => {
    const tmpBalances = bitkubBalances?.result;

    let result =
      !_.isEmpty(tmpBalances) &&
      Object.keys(tmpBalances).map((name) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        let id = useCoin(name);
        let value =
          tmpBalances[name].available + tmpBalances[name].reserved || 0;
        let res = {
          id,
          name,
          value,
        };
        id === 0 && setThaibaht(value);
        return res;
      });

    let cal =
      !_.isEmpty(result) &&
      result?.map((items) => {
        let data = [];
        let found = crypto?.find((element) => element.id === items.id);
        if (!_.isEmpty(found)) {
          let newObj = {
            id: found.id,
            symbol: found.symbol,
            lastPrice: items.value * found.lastPrice,
          };
          data = newObj;
        }
        return data;
      });

    let final =
      !_.isEmpty(cal) &&
      cal?.filter((array) => {
        return !_.isEmpty(array);
      });

    let total =
      !_.isEmpty(final) &&
      final?.reduce((a, b) => ({
        lastPrice: a.lastPrice + b.lastPrice,
      }));

    setTotal(total?.lastPrice);
  };

  useEffect(() => {
    CalculateMoney();
  }, [crypto]);

  return (
    <>
      <Grid container>
        <Grid item className="pt-4 pb-2 ps-2">
          <Typography>Total</Typography>
        </Grid>
      </Grid>
      <Card className={`${styles.card} px-4 py-3`} elevation={0}>
        <Grid container alignItems="center" justifyContent="center" spacing={5}>
          <Grid item xs={12}>
            <MoneyComponent
              total={
                (total || 0) * rates + (SumBinance()?.total || 0) + thaibaht
              }
              date={date}
              time={time}
            />
          </Grid>
        </Grid>
      </Card>

      <Grid container>
        <Grid item className="pt-4 pb-2 ps-2">
          <Typography>Coin</Typography>
        </Grid>
      </Grid>
      <Card className={styles.card} elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          className="pt-2"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            icon={<InsertChartIcon fontSize="small" />}
            label={<span style={{ fontSize: 11 }}>Market</span>}
            {...a11yProps(0)}
          />
          <Tab
            icon={<AccountBalanceWalletIcon fontSize="small" />}
            label={<span style={{ fontSize: 11 }}>Balances</span>}
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ListsCrypto />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ListsBalances />
        </TabPanel>
      </Card>
    </>
  );
}
