import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { systemGpuValue, gpuTempValue } from "../../../../recoil/system/gpu";
import {
  purple,
  red,
  pink,
  teal,
  cyan,
  deepOrange,
  orange,
  lightGreen,
  green,
} from "@material-ui/core/colors";
import _ from "lodash";
import numeral from "numeral";
import bytes from "bytes";
import axios from "axios";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import EcoIcon from "@material-ui/icons/Eco";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import TollIcon from "@material-ui/icons/Toll";
import PowerIcon from "@material-ui/icons/Power";
import SpeedIcon from "@material-ui/icons/Speed";
import AirplayIcon from "@material-ui/icons/Airplay";

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: red[300],
  },
  purple: {
    backgroundColor: purple[300],
  },
  pink: {
    backgroundColor: pink[300],
  },
  teal: {
    backgroundColor: teal[300],
  },
  cyan: {
    backgroundColor: cyan[300],
  },
  deepOrange: {
    backgroundColor: deepOrange[300],
  },
  orange: {
    backgroundColor: orange[400],
  },
  lightGreen: {
    backgroundColor: lightGreen[400],
  },
  green: {
    backgroundColor: green[400],
  },
}));

export default function Gpu() {
  const classes = useStyles();
  const gpu = useRecoilValue(systemGpuValue);
  const gpuTemp = useRecoilValue(gpuTempValue);
  const [gpuManufacturer, setSystemGpuManufacturer] = useState([]);

  const [displays] = gpu?.displays || [];

  const getGpuManufacturer = async () => {
    await axios
      .get("/assets/mock/gpuManufacturer/index.json")
      .then((res) => {
        setSystemGpuManufacturer(res.data);
      })
      .catch((err) => console.log(err));
  };

  const gpuName =
    gpuTemp &&
    gpuManufacturer?.find(
      (items) => items.vendorID === gpuTemp?.subDeviceId?.slice(-4)
    );

  useEffect(() => {
    getGpuManufacturer();
  }, []);

  return (
    <List className="py-0">
      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.pink}>
            <StarBorderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <div className="text-truncate">{gpuTemp?.name || "Unknown"}</div>
          }
          secondary={`${gpuTemp?.vendor || "Unknown"} ${
            gpuName?.manufacturer || ""
          } ${
            gpuTemp?.vram
              ? bytes(gpuTemp?.vram * 1024 ** 2, {
                  unitSeparator: " ",
                })
              : ""
          }`}
        />
        <ListItemSecondaryAction className="end-0">
          {_.isEmpty(gpuTemp) && <CircularProgress size={25} />}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.red}>
            <ScatterPlotIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={"Memory Used"}
          secondary={`${numeral((gpuTemp?.memoryFree || 0) / 1024).format(
            "0.00"
          )} GB free of ${numeral((gpuTemp?.memoryTotal || 0) / 1024).format(
            "0.00"
          )} GB`}
        />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(gpuTemp) ? (
            <Typography>{`${numeral((gpuTemp?.memoryUsed || 0) / 1024).format(
              "0.00"
            )} GB`}</Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.deepOrange}>
            <FlashOnIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={"Power"}
          secondary={`Limit : ${gpuTemp?.powerLimit || 0} W`}
        />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(gpuTemp) ? (
            <Typography>{`${gpuTemp?.powerDraw || 0} W`}</Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.red}>
            <SpeedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Clock Core"} secondary={"MHz"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(gpuTemp) ? (
            <Typography>{`${gpuTemp?.clockCore || 0} MHz`}</Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.purple}>
            <SpeedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Clock Memory"} secondary={"MHz"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(gpuTemp) ? (
            <Typography>{`${gpuTemp?.clockMemory || 0} MHz`}</Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.cyan}>
            <VideoLabelIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Gpu Used"} secondary={"Percent"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(gpuTemp) ? (
            <Typography>{`${gpuTemp?.utilizationGpu || 0} %`}</Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.orange}>
            <TollIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Memory Used"} secondary={"Percent"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(gpuTemp) ? (
            <Typography>{`${gpuTemp?.utilizationMemory || 0} %`}</Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.teal}>
            <EcoIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={"Driver"} secondary={"Version"} />
        <ListItemSecondaryAction className="end-0">
          {!_.isEmpty(gpuTemp) ? (
            <Typography>{gpuTemp?.driverVersion || "Unknown"}</Typography>
          ) : (
            <CircularProgress size={25} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      {!_.isEmpty(displays?.connection) && (
        <ListItem className="px-0">
          <ListItemAvatar>
            <Avatar className={classes.green}>
              <PowerIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={"Connection"}
            secondary={(displays && displays?.model) || "Unknown"}
          />
          <ListItemSecondaryAction className="end-0">
            {!_.isEmpty(displays) ? (
              <Typography>{displays?.connection || "Unknown"}</Typography>
            ) : (
              <CircularProgress size={25} />
            )}
          </ListItemSecondaryAction>
        </ListItem>
      )}

      {displays && (
        <ListItem className="px-0">
          <ListItemAvatar>
            <Avatar className={classes.lightGreen}>
              <AirplayIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={"resolution"}
            secondary={`${displays?.currentRefreshRate || 0} Hz`}
          />
          <ListItemSecondaryAction className="end-0">
            {!_.isEmpty(displays) ? (
              <Typography>{`${displays?.currentResX || 0} x ${
                displays?.currentResY || 0
              }`}</Typography>
            ) : (
              <CircularProgress size={25} />
            )}
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </List>
  );
}
