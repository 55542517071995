import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Grid,
  Card,
} from "@material-ui/core";
import styles from "../../../../theme/style";
import { useRecoilValue } from "recoil";
import { hiveOsValue } from "../../../../recoil/rig/hiveos";
import Progress from "../../../progress";
import numeral from "numeral";

const useStylesGlobal = makeStyles(styles);

export default function Gpu() {
  const styles = useStylesGlobal();
  const hiveos = useRecoilValue(hiveOsValue);
  const { gpu_info, gpu_stats, miners_stats } = hiveos;
  const { hashrates } = miners_stats;

  return (
    <>
      {gpu_info?.map((items, index) => (
        <Card
          className={`${styles.card} p-3 ${
            index + 1 !== gpu_info?.length && "mb-2"
          }`}
          elevation={0}
          key={index}
        >
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <List className="p-0">
                <ListItem className="p-0">
                  <img
                    className="img-fluid me-2"
                    width={35}
                    src="./assets/images/gpu.png"
                    alt="gpu"
                  />
                  <ListItemText
                    primary={`${items.details?.oem} ${items.model}`}
                    secondary={`${items?.details?.mem_type} ${items?.details?.mem}`}
                  />
                </ListItem>
                <ListItem className="py-0">
                  <ListItemText
                    primary={hashrates[0]?.coin}
                    secondary={hashrates[0]?.algo}
                  />
                  <ListItemSecondaryAction className="end-0">
                    <Typography>
                      {(
                        hashrates[0]?.hashes[index] / Math.pow(10, 3) || 0
                      ).toFixed(2)}{" "}
                      Mh
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
                {hashrates[0]?.dcoin && (
                  <ListItem className="py-0">
                    <ListItemText
                      primary={hashrates[0]?.dcoin}
                      secondary={hashrates[0]?.dalgo}
                    />
                    <ListItemSecondaryAction className="end-0">
                      <Typography>
                        {(
                          hashrates[0]?.dhashes[index] / Math.pow(10, 6) || 0
                        ).toFixed(2)}{" "}
                        Gh
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid container className="mb-2">
              <Grid item xs={6}>
                <Progress
                  iconSrc="./assets/images/fire.png"
                  value={numeral(gpu_stats[index]?.temp).format("0,0")}
                  unit="°C"
                />
              </Grid>
              <Grid item xs={6}>
                <Progress
                  iconSrc="./assets/images/ram.png"
                  value={numeral(gpu_stats[index]?.memtemp).format("0,0")}
                  unit="°C"
                />
              </Grid>
              <Grid item xs={6}>
                <Progress
                  iconSrc="./assets/images/lightning.png"
                  value={numeral(gpu_stats[index]?.power).format("0,0")}
                  unit="W"
                />
              </Grid>
              <Grid item xs={6}>
                <Progress
                  iconSrc="./assets/images/fan.png"
                  value={numeral(gpu_stats[index]?.fan).format("0,0")}
                  unit="%"
                />
              </Grid>
            </Grid>
          </Grid>
        </Card>
      ))}
    </>
  );
}
