import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import {
  bitkubBalancesValue,
  binanceBalancesValue,
} from "../../../../recoil/crypto/balances";
import { cryptoMarketValue } from "../../../../recoil/crypto/market";
import { cryptoRatesValue } from "../../../../recoil/crypto/rates";
import _ from "lodash";
import { useCoin } from "../../../../hooks/useCoin";

const useStyles = makeStyles(() => ({
  avatar: {
    border: 0,
  },
}));

const BalancesComponent = React.memo(({ crypto }) => {
  const classes = useStyles();
  const bitkubBalances = useRecoilValue(bitkubBalancesValue);
  const binanceBalances = useRecoilValue(binanceBalancesValue);
  const rates = useRecoilValue(cryptoRatesValue);
  const [result, setResult] = useState([]);
  let tmpBalances = bitkubBalances?.result;

  useEffect(() => {
    let final =
      !_.isEmpty(balancesBinance && balancesBitkub) &&
      merge(balancesBinance, balancesBitkub);
    setResult(final);
  }, []);

  const balancesBitkub =
    tmpBalances &&
    Object.keys(tmpBalances).map((name, index) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      let coinId = useCoin(name);
      let cryptoList = crypto?.find((elem) => elem.id === coinId);
      let lastPrice =
        (coinId === 0 ? 1 : Number(cryptoList?.lastPrice) * rates) || 0;
      let bitkubBalances =
        tmpBalances[name].available + tmpBalances[name].reserved || 0;
      let total = Number((bitkubBalances * lastPrice).toFixed(2));
      let res = {
        id: index,
        name: name === "USDT" ? "BUSD" : name,
        coin: bitkubBalances,
        total,
      };
      return res;
    });

  const balancesBinance = binanceBalances?.map((binance, index) => {
    let bitkub = balancesBitkub?.find(
      (bitkub) => bitkub?.name === binance?.asset
    );
    let coinBitkub = Number(bitkub?.coin || 0);
    let coinBinance = Number(binance?.free || 0);
    let sumCoin = coinBitkub + coinBinance;
    let newId = Math.max.apply(
      null,
      balancesBitkub?.map(function (o) {
        return Number(o.id) + index;
      })
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let coinId = useCoin(bitkub?.name || binance?.asset);
    let cryptoList = crypto?.find((elem) => elem.id === coinId);
    let lastPrice =
      (coinId === 0 ? 1 : Number(cryptoList?.lastPrice) * rates) || 0;
    let total = Number(((coinBitkub + coinBinance) * lastPrice).toFixed(2));
    let data = {
      id: bitkub?.id || newId,
      name: bitkub?.name || binance?.asset || "Unknown",
      coin: sumCoin,
      total,
    };
    return data;
  });

  const merge = (arr1, arr2) => {
    const merged = [...arr1, ...arr2];
    const out = [];
    for (let obj of merged) {
      // const { id, coin } = obj;
      const { id } = obj;
      const found = out.find((obj) => obj.id === id);
      if (found) {
        // found.coin += coin;
      } else {
        out.push({ ...obj });
      }
    }
    return out;
  };

  return (
    <>
      {!_.isEmpty(result) ? (
        result?.map(
          (items, index) =>
            items.coin > 0 && (
              <List key={index} className="py-0">
                <ListItem className="px-0">
                  <ListItemAvatar>
                    <Avatar
                      className={classes.avatar}
                      src={`/assets/images/coin/${items?.name}USDT.png`}
                      alt={items?.name}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={items?.name}
                    secondary={Intl.NumberFormat().format(items?.coin)}
                  />
                  <ListItemSecondaryAction className="end-0">
                    {!_.isEmpty(items) ? (
                      <Typography>
                        {Intl.NumberFormat().format(items?.total)}
                      </Typography>
                    ) : (
                      <CircularProgress size={25} />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            )
        )
      ) : (
        <Grid container justifyContent="center" className="py-5">
          <CircularProgress />
        </Grid>
      )}
    </>
  );
});

export default function Balances() {
  const crypto = useRecoilValue(cryptoMarketValue);
  return <BalancesComponent crypto={crypto} />;
}
