import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Typography } from "@material-ui/core";

export default function Donuts({
  value = 0,
  text = 0,
  display = "",
  maxValue = 100,
  strokeWidth = 17,
}) {
  return (
    <>
      <CircularProgressbar
        value={value}
        text={text}
        strokeWidth={strokeWidth}
        styles={buildStyles({
          strokeLinecap: "butt",
          textSize: 14,
          pathTransitionDuration: 1,
          pathColor: "#16D5DF",
          textColor: "#16D5DF",
          trailColor: "#212529",
        })}
        maxValue={maxValue}
      />
      <Typography align="center" variant="body2" className="pt-2">
        {display}
      </Typography>
    </>
  );
}
