import { atom, selector } from "recoil";

export const systemMemState = atom({
  key: "systemMemState",
  default: null,
});

export const systemMemValue = selector({
  key: "systemMemValue",
  get: ({ get }) => {
    const value = get(systemMemState);
    return value;
  },
});

export const systemMemLayoutState = atom({
  key: "systemMemLayoutState",
  default: null,
});

export const memLayoutValue = selector({
  key: "memLayoutValue",
  get: ({ get }) => {
    const value = get(systemMemLayoutState);
    return value;
  },
});
