import { atom, selector } from "recoil";
import moment from "moment";

export const dateState = atom({
  key: "dateState",
  default: moment().format("Do MMMM YYYY"),
});

export const dateValue = selector({
  key: "dateValue",
  get: ({ get }) => {
    const value = get(dateState);
    return value;
  },
});

export const timeState = atom({
  key: "timeState",
  default: moment().format("HH:mm:ss"),
});

export const timeValue = selector({
  key: "timeValue",
  get: ({ get }) => {
    const value = get(timeState);
    return value;
  },
});
