import { withStyles } from "@material-ui/core/styles";
import { Box, Typography, LinearProgress } from "@material-ui/core";

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#212529",
  },
  bar: {
    borderRadius: 5,
  },
})(LinearProgress);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" className="ps-3 pe-2">
      <Box minWidth={13} mr={1}>
        <img className="img-fluid " width={13} src={props.iconSrc} alt="" />
      </Box>
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2">{`${Math.round(props.value)} ${
          props.unit
        }`}</Typography>
      </Box>
    </Box>
  );
}

export default function Progress({ value = 0, iconSrc = "", unit = "%" }) {
  return (
    <LinearProgressWithLabel value={value} iconSrc={iconSrc} unit={unit} />
  );
}
