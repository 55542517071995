import React from "react";
import { XAxis, YAxis, Area, AreaChart, ResponsiveContainer } from "recharts";

const Charts = React.memo(({ data, dataKey }) => {
  return (
    <ResponsiveContainer width="100%" height={150} className="pt-2">
      <AreaChart
        width={300}
        height={150}
        data={data?.slice(-50)}
        margin={{ right: 20, left: -25 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#16D5DF" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#16D5DF" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          style={{ fontSize: 10 }}
          tickLine={false}
          tickSize={7}
          tick={false}
          name={10}
        />
        <YAxis
          style={{ fontSize: 10 }}
          tickLine={false}
          tickSize={7}
          type="number"
          domain={[0, 100]}
        />
        <Area
          type="natural"
          dataKey={dataKey}
          stroke="#16D5DF"
          fillOpacity={1}
          fill="url(#colorUv)"
          isAnimationActive={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
});

export default Charts;
