export const useCoin = (name) => {
  if (name === "THB") {
    return 0;
  } else if (name === "BTC") {
    return 1;
  } else if (name === "ETH") {
    return 2;
  } else if (name === "XRP") {
    return 3;
  } else if (name === "ADA") {
    return 4;
  } else if (name === "ALPHA") {
    return 5;
  } else if (name === "BNB") {
    return 6;
  } else if (name === "DOGE") {
    return 7;
  } else if (["BUSD", "USDT"].includes(name)) {
    return 8;
  } else if (name === "MATIC") {
    return 9;
  } else if (name === "RVN") {
    return 10;
  } else if (name === "LTC") {
    return 11;
  } else if (name === "NEAR") {
    return 12;
  }
};
