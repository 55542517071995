import { atom, selector } from "recoil";

export const darkModeState = atom({
  key: "darkModeState",
  default: true,
});

export const darkModeUserValue = selector({
  key: "darkModeUserValue",
  get: ({ get }) => {
    const value = get(darkModeState);
    return value;
  },
});
