import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import numeral from "numeral";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useRecoilValue } from "recoil";
import { cryptoMarketValue } from "../../../../recoil/crypto/market";
import { cryptoRatesValue } from "../../../../recoil/crypto/rates";
import _ from "lodash";
import { red, green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: 0,
  },
  red: {
    color: red[300],
  },
  green: {
    color: green[300],
  },
}));

const CryptoComponent = React.memo(({ crypto }) => {
  const classes = useStyles();
  const rate = useRecoilValue(cryptoRatesValue);
  // let result =
  //   crypto &&
  //   Object.keys(crypto).map((e, i) => {
  //     let res = {
  //       name: e,
  //       ...crypto[e],
  //     };
  //     return res;
  //   });

  return (
    <>
      {!_.isEmpty(crypto) ? (
        crypto?.map((items, index) => (
          <List key={index} className="py-0">
            <ListItem className="px-0 pb-0">
              <ListItemAvatar>
                <Avatar
                  className={classes.avatar}
                  src={`/assets/images/coin/${items?.symbol}.png`}
                  alt={items?.symbol}
                />
              </ListItemAvatar>
              <ListItemText
                primary={items?.symbol?.slice(0, -4) || "Unknown"}
                secondary={
                  <>
                    <span>
                      สูงสุด :&nbsp;
                      {Intl.NumberFormat().format(
                        ((items?.highPrice || 0) * rate).toFixed(2)
                      )}
                    </span>
                    <br />
                    <span>
                      ต่ำสุด :&nbsp;
                      {Intl.NumberFormat().format(
                        ((items?.lowPrice || 0) * rate).toFixed(2)
                      )}
                    </span>
                  </>
                }
              />
              <ListItemSecondaryAction className="end-0">
                <ListItemText
                  className="text-end"
                  primary={
                    <Typography>
                      {Intl.NumberFormat().format(
                        ((items?.lastPrice || 0) * rate).toFixed(2)
                      )}
                    </Typography>
                  }
                  secondary={
                    items?.priceChangePercent.charAt(0) !== "-" ? (
                      <span className={classes.green}>
                        <ArrowDropUpIcon fontSize="small" />
                        {numeral(items?.priceChangePercent).format("0.00")} %
                      </span>
                    ) : (
                      <span className={classes.red}>
                        <ArrowDropDownIcon fontSize="small" />
                        {numeral(items?.priceChangePercent).format("0.00")} %
                      </span>
                    )
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        ))
      ) : (
        <Grid container justifyContent="center" className="py-5">
          <CircularProgress />
        </Grid>
      )}
    </>
  );
});

export default function Crypto() {
  const crypto = useRecoilValue(cryptoMarketValue);
  return <CryptoComponent crypto={crypto} />;
}
