// import palette from "./palette";

// const typography = {
//   fontFamily: "'PT Sans Narrow', 'Open Sans', 'Helvetica Neue', sans-serif",
//   h1: {
//     color: palette.text.primary,
//   },
//   h2: {
//     color: palette.text.primary,
//   },
//   h3: {
//     color: palette.text.primary,
//   },
//   h4: {
//     color: palette.text.primary,
//   },
//   h5: {
//     color: palette.text.primary,
//   },
//   h6: {
//     color: palette.text.primary,
//   },
//   subtitle1: {
//     color: palette.text.primary,
//   },
//   subtitle2: {
//     color: palette.text.secondary,
//   },
//   body1: {
//     color: palette.text.primary,
//   },
//   body2: {
//     color: palette.text.secondary,
//   },
// };

// export default typography;

import palette from "./palette";

const typography = {
  fontFamily: "'Open Sans', 'Helvetica Neue', sans-serif",
  h1: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "35px",
    letterSpacing: "-0.24px",
    lineHeight: "40px",
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "29px",
    letterSpacing: "-0.24px",
    lineHeight: "32px",
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "24px",
    letterSpacing: "-0.06px",
    lineHeight: "28px",
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "20px",
    letterSpacing: "-0.06px",
    lineHeight: "24px",
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: "20px",
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "20px",
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: "16px",
    letterSpacing: "-0.05px",
    lineHeight: "25px",
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "-0.05px",
    lineHeight: "21px",
  },
  body1: {
    color: palette.text.primary,
    fontSize: "14px",
    lineHeight: "21px",
  },
  body2: {
    color: palette.text.secondary,
    fontSize: "12px",
    lineHeight: "18px",
  },
};

export default typography;
