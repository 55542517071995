import { atom, selector } from "recoil";

export const verusState = atom({
  key: "verusState",
  default: [],
});

export const verusValue = selector({
  key: "verusValue",
  get: ({ get }) => {
    const value = get(verusState);
    return value;
  },
});
