import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { flexpoolWorkersValue } from "../../../recoil/miner/flexpool";
import { red } from "@material-ui/core/colors";
import WorkIcon from "@material-ui/icons/Work";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: red[300],
  },
}));

export default function Flexpool() {
  const classes = useStyles();
  const flexpoolWorkers = useRecoilValue(flexpoolWorkersValue);
  const miners = flexpoolWorkers || [];

  return (
    <List className="py-0">
      {!_.isEmpty(miners) &&
        miners?.map((items, index) => (
          <ListItem className="px-0">
            <ListItemAvatar>
              <Avatar className={classes.red}>
                <WorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={items.name}
              secondary={
                <Typography
                  style={{
                    color: items?.isOnline ? "lightgreen" : "red",
                  }}
                >
                  {items?.isOnline ? "Online" : "Offline"}
                </Typography>
              }
            />
            <ListItemSecondaryAction className="end-0">
              <Typography>{`${(
                items.currentEffectiveHashrate / Math.pow(10, 6)
              ).toFixed(2)} Mh`}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );
}
