import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { verusValue } from "../../../recoil/miner/verus";
import { red } from "@material-ui/core/colors";
import WorkIcon from "@material-ui/icons/Work";

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: red[300],
  },
}));

export default function Verus() {
  const classes = useStyles();
  const verus = useRecoilValue(verusValue);
  let data = verus?.workers.map((items) => {
    let splitData = items.split(":");
    let data = {
      name: splitData[0],
      hashrateSols: `${(Number(splitData[1]) / Math.pow(10, 6)).toFixed(2)} Mh`,
      shares: `${Intl.NumberFormat().format(
        Number(splitData[2]).toFixed(0)
      )} Shares`,
    };
    return data;
  });

  return (
    <List className="py-0">
      {data?.map((items, index) => (
        <ListItem className="px-0">
          <ListItemAvatar>
            <Avatar className={classes.red}>
              <WorkIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={items.name} secondary={items.shares} />
          <ListItemSecondaryAction className="end-0">
            <Typography>{items.hashrateSols}</Typography>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}
