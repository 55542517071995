import { atom, selector } from "recoil";

export const systemNetworkState = atom({
  key: "systemNetworkState",
  default: null,
});

export const systemNetworkValue = selector({
  key: "systemNetworkValue",
  get: ({ get }) => {
    const value = get(systemNetworkState);
    return value;
  },
});
