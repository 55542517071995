import { atom, selector } from "recoil";

export const duinocoinState = atom({
  key: "duinocoinState",
  default: [],
});

export const duinocoinValue = selector({
  key: "duinocoinValue",
  get: ({ get }) => {
    const value = get(duinocoinState);
    return value;
  },
});

export const duinocoinStatisticState = atom({
  key: "duinocoinStatisticState",
  default: [],
});

export const duinocoinStatisticValue = selector({
  key: "duinocoinStatisticValue",
  get: ({ get }) => {
    const value = get(duinocoinStatisticState);
    return value;
  },
});

export const duinocoinAccountState = atom({
  key: "duinocoinAccountState",
  default: "golfgenesis",
});

export const duinocoinAccountValue = selector({
  key: "duinocoinAccountValue",
  get: ({ get }) => {
    const value = get(duinocoinAccountState);
    return value;
  },
});
