import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../theme/style";
import { useTheme } from "@material-ui/core/styles";
import {
  Tabs,
  Tab,
  Typography,
  Grid,
  Card,
  CircularProgress,
} from "@material-ui/core";
import WorkerFlexpool from "../../components/lists/worker/flexpool";
import WorkerEthermine from "../../components/lists/worker/ethermine";
import WorkerNanopool from "../../components/lists/worker/nanopool";
import WorkerVerus from "../../components/lists/worker/verus";
import WorkerDuinocoin from "../../components/lists/worker/duinocoin";
import { useRecoilValue } from "recoil";
import { flexpoolWorkersValue } from "../../recoil/miner/flexpool";
import { ethermineDashboardValue } from "../../recoil/miner/ethermine";
import { nanopoolUserValue } from "../../recoil/miner/nanopool";
import { duinocoinValue } from "../../recoil/miner/duinocoin";
import { verusValue } from "../../recoil/miner/verus";
import TabPanel from "./TabPanel";
import { TabData } from "./TabData";
import _ from "lodash";
import "moment/locale/th";

const useStylesGlobal = makeStyles(styles);

export default function FullWidthTabs() {
  const styles = useStylesGlobal();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const flexpoolWorkers = useRecoilValue(flexpoolWorkersValue);
  const ethermineDashboard = useRecoilValue(ethermineDashboardValue);
  const nanopoolUser = useRecoilValue(nanopoolUserValue);
  const verus = useRecoilValue(verusValue);
  const duinocoin = useRecoilValue(duinocoinValue);
  const [flexpoolWorkersCount, setFlexpoolWorkersCount] = useState(0);
  const [ethermineWorkerCount, setEthermineWorkerCount] = useState(0);
  const [nanopoolWorkerCount, setNanopoolWorkerCount] = useState(0);
  const [duinocoinWorkerCount, setDuinocoinWorkerCount] = useState(0);
  const [verusWorkerCount, setVerusWorkerCount] = useState(0);

  useEffect(() => {
    !_.isEmpty(flexpoolWorkers) &&
      setFlexpoolWorkersCount(Number(flexpoolWorkers?.length || 0));
  }, [flexpoolWorkers]);

  useEffect(() => {
    !_.isEmpty(ethermineDashboard) &&
      setEthermineWorkerCount(Number(ethermineDashboard?.workers?.length || 0));
  }, [ethermineDashboard]);

  useEffect(() => {
    !_.isEmpty(nanopoolUser) &&
      setNanopoolWorkerCount(Number(nanopoolUser?.workers?.length || 0));
  }, [nanopoolUser]);

  useEffect(() => {
    !_.isEmpty(duinocoin) &&
      setDuinocoinWorkerCount(Number(duinocoin?.result?.miners?.length || 0));
  }, [duinocoin]);

  useEffect(() => {
    !_.isEmpty(verus) &&
      setVerusWorkerCount(Number(verus?.workers?.length || 0));
  }, [verus]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item className="pt-4 pb-2 ps-2">
          <Typography>Miner</Typography>
        </Grid>
      </Grid>
      <Card className={styles.card} elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          className="pt-2"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {TabData?.map((items, index) => (
            <Tab
              key={index}
              icon={items.icon}
              label={<span style={{ fontSize: 11 }}>{items.label}</span>}
              id={`full-width-tab-${index}`}
              ariaControls={`full-width-tabpanel-${index}`}
            />
          ))}
        </Tabs>
        {TabData?.map((items, index) => (
          <TabPanel
            key={index}
            value={value}
            index={index}
            dir={theme.direction}
          >
            {items.tab_panel}
          </TabPanel>
        ))}
      </Card>

      {value === 0 && ethermineWorkerCount > 0 && (
        <>
          <Grid container>
            <Grid item className="pt-4 pb-2 ps-2">
              <Typography>
                Worker {ethermineWorkerCount && `(${ethermineWorkerCount})`}
              </Typography>
            </Grid>
          </Grid>
          <Card className={`${styles.card} px-4 py-3`} elevation={0}>
            {!_.isEmpty(duinocoin) ? (
              <WorkerEthermine />
            ) : (
              <Grid container justifyContent="center" className="py-5">
                <CircularProgress />
              </Grid>
            )}
          </Card>
        </>
      )}

      {value === 1 && flexpoolWorkersCount > 0 && (
        <>
          <Grid container>
            <Grid item className="pt-4 pb-2 ps-2">
              <Typography>
                Worker {flexpoolWorkersCount && `(${flexpoolWorkersCount})`}
              </Typography>
            </Grid>
          </Grid>
          <Card className={`${styles.card} px-4 py-3`} elevation={0}>
            {!_.isEmpty(duinocoin) ? (
              <WorkerFlexpool />
            ) : (
              <Grid container justifyContent="center" className="py-5">
                <CircularProgress />
              </Grid>
            )}
          </Card>
        </>
      )}

      {value === 2 && nanopoolWorkerCount > 0 && (
        <>
          <Grid container>
            <Grid item className="pt-4 pb-2 ps-2">
              <Typography>
                Worker {nanopoolWorkerCount && `(${nanopoolWorkerCount})`}
              </Typography>
            </Grid>
          </Grid>
          <Card className={`${styles.card} px-4 py-3`} elevation={0}>
            {!_.isEmpty(duinocoin) ? (
              <WorkerNanopool />
            ) : (
              <Grid container justifyContent="center" className="py-5">
                <CircularProgress />
              </Grid>
            )}
          </Card>
        </>
      )}

      {value === 3 && duinocoinWorkerCount > 0 && (
        <>
          <Grid container>
            <Grid item className="pt-4 pb-2 ps-2">
              <Typography>
                Worker {duinocoinWorkerCount && `(${duinocoinWorkerCount})`}
              </Typography>
            </Grid>
          </Grid>
          <Card className={`${styles.card} px-4 py-3`} elevation={0}>
            {!_.isEmpty(duinocoin) ? (
              <WorkerDuinocoin />
            ) : (
              <Grid container justifyContent="center" className="py-5">
                <CircularProgress />
              </Grid>
            )}
          </Card>
        </>
      )}

      {value === 4 && verusWorkerCount > 0 && (
        <>
          <Grid container>
            <Grid item className="pt-4 pb-2 ps-2">
              <Typography>
                Worker {verusWorkerCount && `(${verusWorkerCount})`}
              </Typography>
            </Grid>
          </Grid>
          <Card className={`${styles.card} px-4 py-3`} elevation={0}>
            {!_.isEmpty(verus) ? (
              <WorkerVerus />
            ) : (
              <Grid container justifyContent="center" className="py-5">
                <CircularProgress />
              </Grid>
            )}
          </Card>
        </>
      )}
    </>
  );
}
