import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { duinocoinValue } from "../../../recoil/miner/duinocoin";
import { red } from "@material-ui/core/colors";
import WorkIcon from "@material-ui/icons/Work";
import _ from "lodash";
import bytes from "bytes";

const useStyles = makeStyles((theme) => ({
  red: {
    backgroundColor: red[300],
  },
}));

export default function Duinocoin() {
  const classes = useStyles();
  const duinocoin = useRecoilValue(duinocoinValue);
  const [groupMiners, setGroupMiners] = useState({});

  useEffect(() => {
    if (!_.isEmpty(duinocoin)) {
      let workers = duinocoin?.result?.miners;
      let sortWorkers = [...workers].sort((a, b) =>
        a.identifier
          .toLowerCase()
          .localeCompare(b.identifier.toLowerCase(), undefined, {
            numeric: true,
            sensitivity: "base",
          })
      );
      let newWorkers = sortWorkers?.map((items) => {
        return {
          ...items,
          identifier:
            items?.identifier === "None" ? items?.software : items?.identifier,
        };
      });
      let group = newWorkers.reduce((r, a) => {
        r[a.identifier] = [...(r[a.identifier] || []), a];
        return r;
      }, {});
      setGroupMiners(group);
    }
  }, [duinocoin]);

  const Hashrate = (data) => {
    let total = data?.reduce((a, b) => ({
      hashrate: a.hashrate + b.hashrate,
    }));
    let pow = total.hashrate / Math.pow(10, 3) > 1000 ? 6 : 3;
    let unit = total.hashrate / Math.pow(10, 3) > 1000 ? "Mh" : "Kh/s";
    let result = `${(total.hashrate / Math.pow(10, pow)).toFixed(2)} ${unit}`;
    return result;
  };

  const DiffAvg = (data) => {
    // let total = data?.reduce((a, b, _, { length }) => ({
    //   diff: a.diff + b.diff / length,
    // }));
    let result = `Diff ${bytes(data[0].diff, {
      unitSeparator: " ",
    }).replace("B", "")}`;
    return result;
  };

  return (
    <List className="py-0">
      {!_.isEmpty(Object.keys(groupMiners)) &&
        Object.keys(groupMiners)?.map((identifier, index) => (
          <ListItem className="px-0">
            <ListItemAvatar>
              <Avatar className={classes.red}>
                <WorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${identifier} (${groupMiners[identifier].length})`}
              secondary={groupMiners[identifier][0].software}
            />
            <ListItemSecondaryAction className="end-0">
              <ListItemText
                className="text-end"
                primary={Hashrate(groupMiners[identifier])}
                secondary={`${groupMiners[identifier][0].pool} ${DiffAvg(
                  groupMiners[identifier]
                )}`}
              />
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );
}
