const styles = (theme) => ({
  colorfulBg: {
    background: "linear-gradient(to bottom, #11998e, #16635c)",
  },
  card: {
    backgroundColor: "#2A2E32",
  },
  backdrop: {
    zIndex: 2000,
    color: "#FFF",
  },
});

export default styles;
