import { atom, selector } from "recoil";

export const systemBaseBoardState = atom({
  key: "systemBaseBoardState",
  default: null,
});

export const systemBaseBoardValue = selector({
  key: "systemBaseBoardValue",
  get: ({ get }) => {
    const value = get(systemBaseBoardState);
    return value;
  },
});
