import { atom, selector } from "recoil";

export const systemGpuState = atom({
  key: "systemGpuState",
  default: null,
});

export const systemGpuValue = selector({
  key: "systemGpuValue",
  get: ({ get }) => {
    const value = get(systemGpuState);
    return value;
  },
});

export const systemGpuTempState = atom({
  key: "systemGpuTempState",
  default: null,
});

export const gpuTempValue = selector({
  key: "gpuTempValue",
  get: ({ get }) => {
    const value = get(systemGpuTempState);
    return value;
  },
});
