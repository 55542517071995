import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { systemNetworkValue } from "../../../../recoil/system/network";
import NetworkWifiIcon from "@material-ui/icons/NetworkWifi";
import pink from "@material-ui/core/colors/pink";
import _ from "lodash";
import bytes from "bytes";
import cliTruncate from "cli-truncate";

const useStyles = makeStyles((theme) => ({
  pink: {
    backgroundColor: pink[300],
  },
}));

export default function Network() {
  const classes = useStyles();
  const network = useRecoilValue(systemNetworkValue);

  return (
    <List className="py-0">
      {!_.isEmpty(network) ? (
        network.map((items, index) => (
          <ListItem key={index} className="px-0">
            <ListItemAvatar>
              <Avatar className={classes.pink}>
                <NetworkWifiIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={cliTruncate(items?.iface, 25)}
              secondary={items?.ip}
            />
            <ListItemSecondaryAction className="end-0">
              <Typography>
                {bytes(items?.packets_recv, {
                  unitSeparator: " ",
                })}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))
      ) : (
        <Grid container justifyContent="center" className="py-5">
          <CircularProgress />
        </Grid>
      )}
    </List>
  );
}
