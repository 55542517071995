import React, { useEffect, useState } from "react";
import { XAxis, YAxis, Area, AreaChart, ResponsiveContainer } from "recharts";
import moment from "moment";

const Charts = React.memo(({ data }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (data) {
      let items = data?.map((items) => {
        return {
          ...items,
          currentHashrate: items.currentHashrate / Math.pow(10, 6),
          time: moment.unix(items.time).format("HH:mm"),
        };
      });
      setItems(items);
    }
  }, [data]);

  return (
    <ResponsiveContainer width="100%" height={130}>
      <AreaChart
        width={300}
        height={130}
        data={items?.slice(-200)}
        margin={{ right: 0, left: -25 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#16D5DF" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#16D5DF" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          style={{ fontSize: 10 }}
          tickSize={7}
          angle={15}
          dataKey="time"
        />
        <YAxis
          style={{ fontSize: 10 }}
          tickSize={7}
          dataKey="currentHashrate"
        />
        <Area
          type="natural"
          dataKey="currentHashrate"
          stroke="#16D5DF"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
});

export default Charts;
