import { atom, selector } from "recoil";

export const bitkubBalancesState = atom({
  key: "bitkubBalancesState",
  default: null,
});

export const bitkubBalancesValue = selector({
  key: "bitkubBalancesValue",
  get: ({ get }) => {
    const value = get(bitkubBalancesState);
    return value;
  },
});

export const binanceBalancesState = atom({
  key: "binanceBalancesState",
  default: null,
});

export const binanceBalancesValue = selector({
  key: "binanceBalancesValue",
  get: ({ get }) => {
    const value = get(binanceBalancesState);
    return value;
  },
});
