import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./../../theme/style";
import { useTheme } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, Box, Grid, Card } from "@material-ui/core";
import SpeedIcon from "@material-ui/icons/Speed";
import MemoryIcon from "@material-ui/icons/Memory";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import ListsCpu from "../../components/lists/system/cpu";
import ListsMem from "../../components/lists/system/mem";
import ListsDisk from "../../components/lists/system/disk";
import ListsNetwork from "../../components/lists/system/network";
import ListsGpu from "../../components/lists/system/gpu";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import { useRecoilValue } from "recoil";
import { gpuTempValue } from "../../recoil/system/gpu";
import _ from "lodash";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStylesGlobal = makeStyles(styles);

export default function FullWidthTabs() {
  const styles = useStylesGlobal();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const gpuTemp = useRecoilValue(gpuTempValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item className="pt-4 pb-2 ps-2">
          <Typography>Info</Typography>
        </Grid>
      </Grid>
      <Card className={styles.card} elevation={0}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          className="pt-2"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            icon={<SpeedIcon fontSize="small" />}
            label={<span style={{ fontSize: 11 }}>CPU</span>}
            {...a11yProps(0)}
          />
          <Tab
            icon={<MemoryIcon fontSize="small" />}
            label={<span style={{ fontSize: 11 }}>Memory</span>}
            {...a11yProps(1)}
          />
          {!_.isEmpty(gpuTemp) && (
            <Tab
              icon={<VideoLabelIcon fontSize="small" />}
              label={<span style={{ fontSize: 11 }}>GPU</span>}
              {...a11yProps(2)}
            />
          )}
          <Tab
            icon={<DataUsageIcon fontSize="small" />}
            label={<span style={{ fontSize: 11 }}>Disk</span>}
            {...a11yProps(_.isEmpty(gpuTemp) ? 2 : 3)}
          />
          <Tab
            icon={<RssFeedIcon fontSize="small" />}
            label={<span style={{ fontSize: 11 }}>Network</span>}
            {...a11yProps(_.isEmpty(gpuTemp) ? 3 : 4)}
          />
        </Tabs>
        <TabPanel value={value} index={0} dir={theme.direction}>
          <ListsCpu />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ListsMem />
        </TabPanel>
        {!_.isEmpty(gpuTemp) && (
          <TabPanel value={value} index={2} dir={theme.direction}>
            <ListsGpu />
          </TabPanel>
        )}
        <TabPanel
          value={value}
          index={_.isEmpty(gpuTemp) ? 2 : 3}
          dir={theme.direction}
        >
          <ListsDisk />
        </TabPanel>
        <TabPanel
          value={value}
          index={_.isEmpty(gpuTemp) ? 3 : 4}
          dir={theme.direction}
        >
          <ListsNetwork />
        </TabPanel>
      </Card>
    </>
  );
}
