import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ScatterPlotIcon from "@material-ui/icons/ScatterPlot";
import HdrWeakIcon from "@material-ui/icons/HdrWeak";
import { useRecoilValue } from "recoil";
import { systemMemValue, memLayoutValue } from "../../../../recoil/system/mem";
import bytes from "bytes";
import _ from "lodash";
import { teal, pink } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  teal: {
    backgroundColor: teal[300],
  },
  pink: {
    backgroundColor: pink[300],
  },
}));

export default function Mem() {
  const classes = useStyles();
  const mem = useRecoilValue(systemMemValue);
  const memLayout = useRecoilValue(memLayoutValue);

  return (
    <List className="py-0">
      <ListItem className="px-0">
        <ListItemAvatar>
          <Avatar className={classes.teal}>
            <ScatterPlotIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={"Memory Used"}
          secondary={`${
            bytes(mem?.available, { unitSeparator: " " }) || "0.00 GB"
          } free of ${bytes(mem?.total, { unitSeparator: " " }) || "0.00 GB"}`}
        />
        <ListItemSecondaryAction className="end-0">
          <Typography>
            {!_.isEmpty(mem) ? (
              <Typography>
                {bytes(mem?.used, { unitSeparator: " " }) || "0.00 GB"}
              </Typography>
            ) : (
              <CircularProgress size={25} />
            )}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>

      {memLayout?.map((items, index) => (
        <ListItem key={index} className="px-0">
          <ListItemAvatar>
            <Avatar className={classes.pink}>
              <HdrWeakIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={items?.bank || `Memory ${index + 1}`}
            secondary={items?.partNum || items?.type || "Unknown"}
          />
          <ListItemSecondaryAction className="end-0">
            <Typography style={{ fontSize: 13 }}>
              {`${bytes(items?.size, { unitSeparator: " " }) || "0.00 GB"} / ${
                items?.clockSpeed || 0
              } MHz`}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}
