import { atom, selector } from "recoil";

export const nanopoolUserState = atom({
  key: "nanopoolUserState",
  default: null,
});

export const nanopoolUserValue = selector({
  key: "nanopoolUserValue",
  get: ({ get }) => {
    const value = get(nanopoolUserState);
    return value;
  },
});

export const nanopoolChartState = atom({
  key: "nanopoolChartState",
  default: null,
});

export const nanopoolChartValue = selector({
  key: "nanopoolChartValue",
  get: ({ get }) => {
    const value = get(nanopoolChartState);
    return value;
  },
});
