import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Avatar,
  Typography,
  Grid,
} from "@material-ui/core";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  duinocoinValue,
  duinocoinStatisticValue,
  duinocoinAccountValue,
  duinocoinAccountState,
} from "../../../../recoil/miner/duinocoin";
import { dateValue, timeValue } from "../../../../recoil/datatime";
import { teal, deepOrange } from "@material-ui/core/colors";
import _ from "lodash";
import SpeedIcon from "@material-ui/icons/Speed";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { cryptoRatesValue } from "../../../../recoil/crypto/rates";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  teal: {
    backgroundColor: teal[300],
  },
  deepOrange: {
    backgroundColor: deepOrange[300],
  },
}));

const MoneyComponent = React.memo(({ total, date, time }) => {
  return (
    <>
      <Typography>มูลค่าทั้งหมด</Typography>
      <Typography variant="h4" className="pt-4 pb-2" align="center">
        {Intl.NumberFormat().format(total.toFixed(2))}
      </Typography>
      <Typography variant="body2" align="center">
        ข้อมูล ณ : {time}
      </Typography>
      <Typography variant="body2" className="pb-4" align="center"></Typography>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="body2">{date}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography align="right">THB</Typography>
        </Grid>
      </Grid>
    </>
  );
});

export default function Duinocoin() {
  const classes = useStyles();
  const date = useRecoilValue(dateValue);
  const time = useRecoilValue(timeValue);
  const duinocoin = useRecoilValue(duinocoinValue);
  const duinocoinStatistic = useRecoilValue(duinocoinStatisticValue);
  const [, setDuinocoinAccount] = useRecoilState(duinocoinAccountState);
  const duinocoinAccount = useRecoilValue(duinocoinAccountValue);
  const rates = useRecoilValue(cryptoRatesValue);
  const [balance, setBalance] = useState(0);
  const [totalHashrate, setTotalHashrate] = useState(0);

  useEffect(() => {
    if (!_.isEmpty(duinocoin)) {
      setBalance(duinocoin?.result?.balance?.balance);

      let miners = duinocoin?.result?.miners || [];
      let totalHashrate = !_.isEmpty(miners)
        ? miners?.reduce((a, b) => ({
            hashrate: a.hashrate + b.hashrate,
          }))
        : 0;
      let hashrate = totalHashrate?.hashrate || 0;
      let pow = hashrate / Math.pow(10, 3) > 1000 ? 6 : 3;
      let unit = hashrate / Math.pow(10, 3) > 1000 ? "Mh" : "Kh/s";
      let TotalHashrateFinal = `${(hashrate / Math.pow(10, pow)).toFixed(
        2
      )} ${unit}`;
      setTotalHashrate(TotalHashrateFinal);
    }
  }, [duinocoin]);

  const handleRadioChange = (e) => {
    setDuinocoinAccount(e.target.value);
  };

  return (
    <>
      <FormControl component="fieldset" className="pb-4">
        <FormLabel component="legend">บัญชีผู้ใช้งาน</FormLabel>
        <RadioGroup
          row
          aria-label="account"
          name="account"
          defaultValue={duinocoinAccount}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="golfgenesis"
            control={<Radio color="primary" />}
            label="golfgenesis"
            labelPlacement="end"
          />
          <FormControlLabel
            value="golfgenesis1"
            control={<Radio color="primary" />}
            label="golfgenesis1"
            labelPlacement="end"
          />
        </RadioGroup>
      </FormControl>

      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <MoneyComponent
            total={
              (balance || 0) * (duinocoinStatistic["Duco price"] || 0) * rates
            }
            date={date}
            time={time}
          />
        </Grid>
      </Grid>

      <List className="pb-0 pt-4">
        <ListItem className="px-0">
          <ListItemAvatar>
            <Avatar className={classes.deepOrange}>
              <SpeedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"Total"} secondary={"Hashrate"} />
          <ListItemSecondaryAction className="end-0">
            <Typography>{totalHashrate}</Typography>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem className="px-0">
          <ListItemAvatar>
            <Avatar className={classes.teal}>
              <AttachMoneyIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={"Balance"} secondary={"DUCO"} />
          <ListItemSecondaryAction className="end-0">
            <Typography>{balance?.toFixed(6) || 0}</Typography>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
}
