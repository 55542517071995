import { atom, selector } from "recoil";

export const systemCpuState = atom({
  key: "systemCpuState",
  default: null,
});

export const systemCpuValue = selector({
  key: "systemCpuValue",
  get: ({ get }) => {
    const value = get(systemCpuState);
    return value;
  },
});

export const systemCpuUsedState = atom({
  key: "systemCpuUsedState",
  default: null,
});

export const systemCpuUsedValue = selector({
  key: "systemCpuUsedValue",
  get: ({ get }) => {
    const value = get(systemCpuUsedState);
    return value;
  },
});

export const systemCpuUsedChartsState = atom({
  key: "systemCpuUsedChartsState",
  default: [],
});

export const systemCpuUsedChartsValue = selector({
  key: "systemCpuUsedChartsValue",
  get: ({ get }) => {
    const value = get(systemCpuUsedChartsState);
    return value;
  },
});

export const systemCpuTemperaturesState = atom({
  key: "systemCpuTemperaturesState",
  default: null,
});

export const systemCpuTemperaturesValue = selector({
  key: "systemCpuTemperaturesValue",
  get: ({ get }) => {
    const value = get(systemCpuTemperaturesState);
    return value;
  },
});

export const systemCpuTemperaturesChartsState = atom({
  key: "systemCpuTemperaturesChartsState",
  default: [],
});

export const systemCpuTemperaturesChartsValue = selector({
  key: "systemCpuTemperaturesChartsValue",
  get: ({ get }) => {
    const value = get(systemCpuTemperaturesChartsState);
    return value;
  },
});
